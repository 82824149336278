import styled from 'styled-components/native'
import {disabledStateHelper, fontLContrast, fontMContrast, fontSContrast} from '@ltb/libs/design-system'
import {GlobalThemeType} from '@ltb/libs/design-system'

export type ButtonSize = 'small' | 'medium' | 'large'
export type ButtonLayout = 'fixed' | 'responsive'
export type ButtonVariant = 'default' | 'outline' | 'muted' | 'linked'

const borderRadiusMap: Record<ButtonSize, number> = {
  small: 4,
  medium: 4,
  large: 8,
}

const fontMap: Record<ButtonSize, string> = {
  small: fontSContrast,
  medium: fontMContrast,
  large: fontLContrast,
}

const heightMap: Record<ButtonSize, number> = {
  small: 24,
  medium: 32,
  large: 48,
}

const paddingMap: Record<ButtonSize, {noIcon: number; internalIcon: number; icon: number}> = {
  small: {noIcon: 12, internalIcon: 4, icon: 8},
  medium: {noIcon: 16, internalIcon: 6, icon: 10},
  large: {noIcon: 24, internalIcon: 8, icon: 16},
}

const layoutMap: Record<ButtonLayout, number> = {
  fixed: 0,
  responsive: 1,
}

type ButtonBodyProps = {
  layout: ButtonLayout
  size: ButtonSize
  startIcon: boolean
  endIcon: boolean
  type: keyof typeof GlobalThemeType
  variant: ButtonVariant
  disabled: boolean
}

export const ButtonBody = styled.View<ButtonBodyProps>`
  display: flex;
  flex-direction: row;
  flex-grow: ${({layout}): number => layoutMap[layout]};
  align-items: center;
  justify-content: center;
  ${({layout}) => layout === 'fixed' && 'align-self: flex-start;'}

  height: ${({size}) => heightMap[size]}px;
  border-radius: ${({size}) => borderRadiusMap[size]}px;
  padding-left: ${({size, startIcon}) => (startIcon ? paddingMap[size].icon : paddingMap[size].noIcon)}px;
  padding-right: ${({size, endIcon}) => (endIcon ? paddingMap[size].icon : paddingMap[size].noIcon)}px;

  background-color: ${({theme, type}) => theme[type].background};
  border-color: ${({theme, type}) => theme[type].background};

  ${({variant, type, theme}) => {
    if (variant === 'outline')
      return `
        background-color: transparent;
        border-width: 1px;
        color: ${theme[type].background};
      `
    if (variant === 'muted')
      return `
        background-color: ${theme[type].color};
        color: ${theme[type].background};
      `
    if (variant === 'linked')
      return `
        background-color: transparent;
        color: ${theme[type].background};
      `
    return `color: ${theme[type].color};`
  }}

  ${disabledStateHelper}
`

type ButtonTextProps = {
  startIcon?: boolean
  endIcon?: boolean
  type: keyof typeof GlobalThemeType
  size: ButtonSize
  variant: ButtonVariant
}

export const ButtonText = styled.Text<ButtonTextProps>`
  padding-left: ${({size, startIcon}) => (startIcon ? paddingMap[size].internalIcon : 0)}px;
  padding-right: ${({size, endIcon}) => (endIcon ? paddingMap[size].internalIcon : 0)}px;

  color: ${({variant, type, theme}) => {
    if (variant === 'outline') return theme[type].background
    if (variant === 'muted') return theme[type].background
    if (variant === 'linked') return theme[type].background
    return theme[type].color
  }};
  ${({size}) => fontMap[size]};
`

export const ButtonTextWrapper = styled.View`
  align-items: center;
  justify-content: center;
  flex-direction: row;
`
