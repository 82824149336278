// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `*,
*::before,
*::after {
  box-sizing: border-box;
}
* {
  margin: 0;
  outline: none;
}

html {
  -moz-text-size-adjust: none;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
  overscroll-behavior: none;
}

body {
  min-height: 100vh;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  overscroll-behavior: none;
}

img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}

input,
button,
textarea,
select {
  font: inherit;
}

textarea:not([rows]) {
  min-height: 10em;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}

#root {
  isolation: isolate;
  min-height: 100vh;
  display: flex;
}

ul[role='list'],
ol[role='list'] {
  list-style: none;
}

:target {
  scroll-margin-block: 5ex;
}
`, "",{"version":3,"sources":["webpack://./libs/design-system/global-reset.css"],"names":[],"mappings":"AAAA;;;EAGE,sBAAsB;AACxB;AACA;EACE,SAAS;EACT,aAAa;AACf;;AAEA;EACE,2BAA2B;EAC3B,8BAA8B;EAC9B,sBAAsB;EACtB,yBAAyB;AAC3B;;AAEA;EACE,iBAAiB;EACjB,gBAAgB;EAChB,mCAAmC;EACnC,yBAAyB;AAC3B;;AAEA;;;;;EAKE,cAAc;EACd,eAAe;AACjB;;AAEA;;;;EAIE,aAAa;AACf;;AAEA;EACE,gBAAgB;AAClB;;AAEA;;;;;;;EAOE,yBAAyB;AAC3B;;AAEA;EACE,kBAAkB;EAClB,iBAAiB;EACjB,aAAa;AACf;;AAEA;;EAEE,gBAAgB;AAClB;;AAEA;EACE,wBAAwB;AAC1B","sourcesContent":["*,\n*::before,\n*::after {\n  box-sizing: border-box;\n}\n* {\n  margin: 0;\n  outline: none;\n}\n\nhtml {\n  -moz-text-size-adjust: none;\n  -webkit-text-size-adjust: none;\n  text-size-adjust: none;\n  overscroll-behavior: none;\n}\n\nbody {\n  min-height: 100vh;\n  line-height: 1.5;\n  -webkit-font-smoothing: antialiased;\n  overscroll-behavior: none;\n}\n\nimg,\npicture,\nvideo,\ncanvas,\nsvg {\n  display: block;\n  max-width: 100%;\n}\n\ninput,\nbutton,\ntextarea,\nselect {\n  font: inherit;\n}\n\ntextarea:not([rows]) {\n  min-height: 10em;\n}\n\np,\nh1,\nh2,\nh3,\nh4,\nh5,\nh6 {\n  overflow-wrap: break-word;\n}\n\n#root {\n  isolation: isolate;\n  min-height: 100vh;\n  display: flex;\n}\n\nul[role='list'],\nol[role='list'] {\n  list-style: none;\n}\n\n:target {\n  scroll-margin-block: 5ex;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
