import {fontM, fontSContrast, fontXS, GlobalThemeType} from '@ltb/libs/design-system'
import styled from 'styled-components/native'
import {css} from 'styled-components/native'

export type TextInputVariant = 'default' | keyof typeof GlobalThemeType
export type TextInputBackground = 'background' | 'midground'

const transitionColors = css`
  transition: color 0.2s, background-color 0.2s;
`

export const TextInputWrapper = styled.View<{disabled?: boolean}>`
  height: 76px;
  width: 100%;
  opacity: ${({disabled}) => (disabled ? 0.5 : 1)};
`

export const TextInputLabel = styled.Text<{variant: TextInputVariant}>`
  color: ${({theme, variant}) => (variant !== 'default' ? theme[variant].background : theme.primary)};
  ${fontSContrast}
  padding-bottom: 6px;
  ${transitionColors}
`

export const TextInputBox = styled.TextInput<{
  variant: TextInputVariant
  background: TextInputBackground
}>`
  background-color: ${({theme, background}) => (background === 'midground' ? theme.midground : theme.background)};
  color: ${({theme, variant}) => (variant !== 'default' ? theme[variant].background : theme.primary)};
  border-radius: 5px;
  border: 1px solid ${({theme, variant}) => (variant !== 'default' ? theme[variant].background : theme.division)};
  padding: 10px;
  ${fontM}
  ${transitionColors}
`

export const TextInputMessage = styled.Text<{variant: TextInputVariant}>`
  color: ${({theme, variant}) => (variant !== 'default' ? theme[variant].background : theme.primary)};
  ${fontXS}
  ${transitionColors}
`
