import {useSafeAreaInsets} from 'react-native-safe-area-context'
import {Iconography, Icons} from '@ltb/libs/design-system'
import {HeaderWrapper, HeadingText, IconWrapper} from './header.styled'
import {GestureResponderEvent, View} from 'react-native'
import {Pressable} from '../pressable'

type HeaderProps = {
  title?: string
  leftIcon?: Icons
  leftOnPress?: (e: GestureResponderEvent) => void
  rightIcon?: Icons
  rightOnPress?: (e: GestureResponderEvent) => void
}

export const Header = ({title, leftIcon, leftOnPress, rightIcon, rightOnPress}: HeaderProps) => {
  const insets = useSafeAreaInsets()

  const LeftPress = leftOnPress ? Pressable : View
  const RightPress = rightOnPress ? Pressable : View

  return (
    <HeaderWrapper insets={insets}>
      <LeftPress onPress={leftOnPress ? leftOnPress : () => {}}>
        <IconWrapper>{leftIcon && <Iconography icon={leftIcon} size={24} />}</IconWrapper>
      </LeftPress>

      <HeadingText>{title}</HeadingText>

      <RightPress onPress={rightOnPress ? rightOnPress : () => {}} testID={`right-icon-${rightIcon}`}>
        <IconWrapper>{rightIcon && <Iconography icon={rightIcon} size={24} />}</IconWrapper>
      </RightPress>
    </HeaderWrapper>
  )
}
