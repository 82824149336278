import {useWindowDimensions} from 'react-native'
import {BackgroundPressable, ModalContainer, SideModalWrapper} from './side-modal.styles'
import {SlideIn} from '@ltb/libs/animations/slide-in'
import {PropsWithChildren} from 'react'

type SideModalProps = {
  onClose: () => void
  originDirection: 'left' | 'right'
  storybookWidth?: number
}

export const SideModal = ({children, onClose, originDirection, storybookWidth}: PropsWithChildren<SideModalProps>) => {
  const {width} = useWindowDimensions()

  return (
    <SideModalWrapper>
      <SlideIn originDirection={originDirection}>
        <BackgroundPressable onPress={onClose} originDirection={originDirection} testID="side-modal-background">
          <ModalContainer width={(storybookWidth || width) * 0.8} originDirection={originDirection}>
            {children}
          </ModalContainer>
        </BackgroundPressable>
      </SlideIn>
    </SideModalWrapper>
  )
}
