import {fontM, fontXLContrast} from '@ltb/libs/design-system'
import {EdgeInsets, useSafeAreaInsets} from 'react-native-safe-area-context'
import styled from 'styled-components/native'

const FourZeroFourWrapper = styled.View<{insets: EdgeInsets}>`
  display: flex;
  flex-grow: 1;
  background-color: ${({theme}) => theme.background};
  align-items: center;
  justify-content: center;
  padding-top: ${({insets}) => insets.top}px;
  padding-bottom: ${({insets}) => insets.bottom}px;
  padding-left: ${({insets}) => insets.left}px;
  padding-right: ${({insets}) => insets.right}px;
`

const InternalWrapper = styled.View`
  max-width: 500px;
  padding-left: 16px;
  padding-right: 16px;
  flex-grow: 1;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  gap: 10px;
`

const HeadlineText = styled.Text`
  color: ${({theme}) => theme.primary};
  ${fontXLContrast}
`

const SubHeadlineText = styled.Text`
  color: ${({theme}) => theme.primary};
  ${fontM}
`

export const FourZeroFour = ({}) => {
  const insets = useSafeAreaInsets()

  return (
    <FourZeroFourWrapper insets={insets}>
      <InternalWrapper>
        <HeadlineText>404</HeadlineText>
        <SubHeadlineText>
          We couldn`t find the resource you were looking for, please try again later or contact support.
        </SubHeadlineText>
      </InternalWrapper>
    </FourZeroFourWrapper>
  )
}
