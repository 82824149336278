import {fontMContrast} from '@ltb/libs/design-system'
import {EdgeInsets} from 'react-native-safe-area-context'
import styled from 'styled-components/native'

export const InternalWrapper = styled.View<{insets: EdgeInsets}>`
  padding-top: ${({insets}) => `${insets.top}px`};
  padding-bottom: ${({insets}) => `${insets.bottom}px`};
`

export const MenuItemWrapper = styled.View<{insets: EdgeInsets}>`
  padding-left: ${({insets}) => `${insets.left}px`};
`

export const MenuInternalWrapper = styled.View`
  padding: 16px;
`

export const MenuText = styled.Text`
  color: ${({theme}) => theme.primary};
  ${fontMContrast}
`
