import {fontLContrast} from '@ltb/libs/design-system/'
import styled from 'styled-components/native'
import {ScrollView} from 'react-native'
import {Button} from '@ltb/libs/components/button'
import {NavigationProp, useNavigation} from '@react-navigation/native'
import {RouteSignedOutParams} from '@ltb/libs/navigation/signed-out-navigator'
import {ROUTE_LOGIN, ROUTE_SIGN_UP} from '@ltb/libs/navigation/constants'

const StyledText = styled.Text`
  color: ${({theme}) => theme.primary};
  ${fontLContrast}
`

export const SignedOutLanding = () => {
  const navigation = useNavigation<NavigationProp<RouteSignedOutParams>>()

  return (
    <ScrollView contentInsetAdjustmentBehavior="automatic">
      <StyledText>Little Tin Box - Signed Out</StyledText>
      <Button layout="fixed" size="medium" type="info" onPress={() => navigation.navigate(ROUTE_LOGIN)}>
        Login
      </Button>
      <Button layout="fixed" size="medium" type="info" onPress={() => navigation.navigate(ROUTE_SIGN_UP)}>
        Sign Up
      </Button>
    </ScrollView>
  )
}
