import Bank from './assets/internal-icons/bank.svg'
import Check from './assets/internal-icons/check.svg'
import ChevronDown from './assets/internal-icons/chevron-down.svg'
import ChevronLeft from './assets/internal-icons/chevron-left.svg'
import ChevronRight from './assets/internal-icons/chevron-right.svg'
import Circle from './assets/internal-icons/circle.svg'
import Clock from './assets/internal-icons/clock.svg'
import Close from './assets/internal-icons/close.svg'
import Cloud from './assets/internal-icons/cloud.svg'
import Delete from './assets/internal-icons/delete.svg'
import Demography from './assets/internal-icons/demography.svg'
import Edit from './assets/internal-icons/edit.svg'
import Email from './assets/internal-icons/email.svg'
import Error from './assets/internal-icons/error.svg'
import Filter from './assets/internal-icons/filter.svg'
import Help from './assets/internal-icons/help.svg'
import House from './assets/internal-icons/house.svg'
import KidStar from './assets/internal-icons/kid-star.svg'
import Label from './assets/internal-icons/label.svg'
import Land from './assets/internal-icons/land.svg'
import Menu from './assets/internal-icons/menu.svg'
import People from './assets/internal-icons/people.svg'
import PiggyBank from './assets/internal-icons/piggy-bank.svg'
import Quiz from './assets/internal-icons/quiz.svg'
import Scroll from './assets/internal-icons/scroll.svg'
import Search from './assets/internal-icons/search.svg'
import Shapes from './assets/internal-icons/shapes.svg'
import SquareCheck from './assets/internal-icons/square-check.svg'
import Square from './assets/internal-icons/square.svg'
import Storefront from './assets/internal-icons/storefront.svg'
import Wrench from './assets/internal-icons/wrench.svg'

export const InternalIcons = {
  Bank,
  Check,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  Circle,
  Clock,
  Close,
  Cloud,
  Delete,
  Demography,
  Edit,
  Email,
  Error,
  Filter,
  Help,
  House,
  KidStar,
  Label,
  Land,
  Menu,
  People,
  PiggyBank,
  Quiz,
  Scroll,
  Search,
  Shapes,
  SquareCheck,
  Square,
  Storefront,
  Wrench,
}
