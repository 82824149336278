import styled from 'styled-components/native'

type ProgressPipsSize = 'medium'

type ProgressPipsProps = {
  currentStep: number
  total: number
  size: ProgressPipsSize
}

const pipSizeMap: Record<ProgressPipsSize, {size: string; radius: string}> = {
  medium: {size: '16px', radius: '8px'},
}

const Pip = styled.View<{isActive: boolean; isComplete: boolean; size: ProgressPipsSize}>`
  box-sizing: border-box;
  width: ${({size}) => pipSizeMap[size].size};
  height: ${({size}) => pipSizeMap[size].size};
  border-width: 1px;
  border-style: solid;
  border-color: ${({theme}) => theme.division};
  border-radius: ${({size}) => pipSizeMap[size].radius};
  background-color: ${({isActive, isComplete, theme}) => {
    if (isActive) return theme.secondary
    if (isComplete) return theme.primary
    return theme.background
  }};
  display: flex;
  justify-content: center;
  align-items: center;
`

const PipsWrapper = styled.View`
  display: flex;
  width: 100%;
  flex-direction: row;
  gap: 4px;
  justify-content: center;
  align-items: center;
`

export const ProgressPips = ({currentStep, total, size = 'medium'}: ProgressPipsProps) => {
  const pips = Array.from({length: total}, (_, index) => {
    const isActive = index === currentStep
    const isComplete = index < currentStep
    return <Pip key={index} isActive={isActive} isComplete={isComplete} size={size} />
  })

  return <PipsWrapper>{pips}</PipsWrapper>
}
