import {fontLContrast, fontM, fontMContrast, fontS} from '@ltb/libs/design-system'
import styled from 'styled-components/native'

export const LandingWrapper = styled.View`
  flex-grow: 1;
  align-items: center;
`

export const InternalView = styled.View`
  display: flex;
  flex-grow: 1;
  background-color: ${({theme}) => theme.background};
  align-items: center;
  justify-content: center;
`

export const InternalContent = styled.View`
  max-width: 500px;
  padding-left: 16px;
  padding-right: 16px;
  flex-grow: 1;
  width: 100%;

  display: flex;
  flex-direction: column;

  gap: 20px;
`

export const HeadingText = styled.Text`
  color: ${({theme}) => theme.primary};
  ${fontLContrast}
  text-align: center;
  padding-top: 24px;
`

export const ContentText = styled.Text`
  color: ${({theme}) => theme.primary};
  ${fontM}
  padding-top: 8px;
  padding-bottom: 24px;
  text-align: center;
`

export const CardInternalWrapper = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
`

export const CardContentWrapper = styled.View`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`

export const CardTitle = styled.Text`
  ${fontMContrast}
  color: ${({theme}) => theme.primary};
`

export const CardContent = styled.Text`
  ${fontS}
  color: ${({theme}) => theme.secondary};
`

export const CardLeft = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
`
