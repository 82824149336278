import {AnalyticClickParams} from '@ltb/libs/contexts/analytics-context/@types'
import {useAnalytics} from '@ltb/libs/contexts/analytics-context'
import {useRoute} from '@react-navigation/native'
import React, {PropsWithChildren} from 'react'
import {Animated, GestureResponderEvent, PressableProps, Pressable as RNPressable} from 'react-native'

export type TouchableButtonProps = PropsWithChildren<PressableProps> & {
  onPressAnalytics?: AnalyticClickParams
  onPress: (e: GestureResponderEvent) => void
}

export const Pressable = ({disabled, children, onPressAnalytics, onPress, ...rest}: TouchableButtonProps) => {
  const route = useRoute()
  const {click} = useAnalytics()

  const animated = new Animated.Value(disabled ? 0.5 : 1)

  const fadeIn = () => {
    Animated.timing(animated, {
      toValue: 0.5,
      duration: 150,
      useNativeDriver: true,
    }).start()
  }
  const fadeOut = () => {
    Animated.timing(animated, {
      toValue: 1,
      duration: 250,
      useNativeDriver: true,
    }).start()
  }

  const proxyOnPress = (e: GestureResponderEvent) => {
    if (onPressAnalytics) {
      click({
        screen: route?.name,
        ...onPressAnalytics,
      })
    }

    onPress(e)
  }

  const styledView = {
    opacity: animated,
  }

  return (
    <RNPressable
      role="button"
      testID="pressable"
      {...rest}
      disabled={disabled}
      onPress={proxyOnPress}
      onPressIn={fadeIn}
      onPressOut={fadeOut}>
      <Animated.View style={styledView}>{children}</Animated.View>
    </RNPressable>
  )
}
