import Apple from './assets/company-logos/apple.svg'
import BritishGas from './assets/company-logos/british-gas.svg'
import Google from './assets/company-logos/google.svg'
import OctopusEnergy from './assets/company-logos/octopus-energy.svg'
import UnitedUtilities from './assets/company-logos/united-utilities.svg'
import VirginMedia from './assets/company-logos/virgin-media.svg'
import LittleTinBox from './assets/company-logos/little-tin-box.svg'

export const CompanyLogos = {
  Apple,
  BritishGas,
  Google,
  OctopusEnergy,
  UnitedUtilities,
  VirginMedia,
  LittleTinBox,
}
