import {LazyQueryExecFunction} from '@apollo/client'
import {
  CurrentUserQuery,
  CurrentUserQueryVariables,
  UserFragment,
  useCurrentUserLazyQuery,
} from '@ltb/libs/graphql/core'
import {ROUTE_SPLASH} from '@ltb/libs/navigation/constants'
import {RouteRootParams} from '@ltb/libs/navigation/root-navigator'
import {NavigationProp, useNavigation} from '@react-navigation/native'
import React, {createContext, useState, useContext, PropsWithChildren} from 'react'
import {clientLogout} from '@ltb/libs/authentication/logout'

type UserProviderProps = {
  user: UserFragment
}

type UserContextType = {
  user: UserFragment
  setUser: React.Dispatch<React.SetStateAction<UserFragment>>
  refetchUser: LazyQueryExecFunction<CurrentUserQuery, CurrentUserQueryVariables>
  logoutUser: () => void
}

/* istanbul ignore next */
export const UserContext = createContext<UserContextType>({
  user: {} as UserFragment,
  setUser: () => {},
  // @ts-expect-error
  refetchUser: async () => {},
  logoutUser: () => {},
})

export const UserProvider = ({user: initialUser, children}: PropsWithChildren<UserProviderProps>) => {
  const [user, setUser] = useState<UserFragment>(initialUser)
  const navigation = useNavigation<NavigationProp<RouteRootParams>>()

  if (!user) {
    navigation.navigate(ROUTE_SPLASH)
  }

  const [refetchUser] = useCurrentUserLazyQuery({
    onCompleted: data => {
      console.log('refetch done', data)
      setUser(data.currentUser)
    },
  })

  const logoutUser = () => {
    navigation.navigate(ROUTE_SPLASH)
    clientLogout()
    // @ts-expect-error
    setUser(null)
  }

  return <UserContext.Provider value={{user, setUser, refetchUser, logoutUser}}>{children}</UserContext.Provider>
}

export const useUser = () => useContext(UserContext)
