import React, {createContext, useContext, PropsWithChildren} from 'react'
import cacheConfig from './cache/config.json'
import {CategoryFragment} from '@ltb/libs/graphql/cms'

type CMSContextType = {
  categories: CategoryFragment[]
}

/* istanbul ignore next */
const CMSContext = createContext<CMSContextType>({
  categories: cacheConfig.data.categories as CategoryFragment[],
})

export const CMSProvider = ({children}: PropsWithChildren) => {
  const categories = cacheConfig.data.categories as CategoryFragment[]

  return <CMSContext.Provider value={{categories}}>{children}</CMSContext.Provider>
}

export const useCMS = () => useContext(CMSContext)
