import type {ScreenFragment} from '@ltb/libs/graphql/cms'

export const getNextScreen = (screens: ScreenFragment[], currentScreen: ScreenFragment): ScreenFragment | undefined => {
  if (!currentScreen) return undefined
  if (!screens) return undefined
  if (!screens.length) return undefined
  if (screens.length === 1) return undefined

  const currentScreenIndex = screens.findIndex(scr => {
    return scr.id === currentScreen.id
  })

  if (currentScreenIndex === -1) return undefined
  if (currentScreenIndex === screens.length - 1) return undefined

  return screens[currentScreenIndex + 1]
}
