import {TextInputProps} from 'react-native'
import {
  TextInputBackground,
  TextInputBox,
  TextInputLabel,
  TextInputMessage,
  TextInputVariant,
  TextInputWrapper,
} from './text-input.styles'
import {useTheme} from 'styled-components/native'

type CustomTextInputProps = TextInputProps & {
  id: string
  label: string
  disabled?: boolean
  message?: string
  background?: TextInputBackground
  variant?: TextInputVariant
}

export const TextInput = ({
  id,
  label,
  message,
  disabled,
  background = 'midground',
  variant = 'default',
  ...rest
}: CustomTextInputProps) => {
  const theme = useTheme()
  return (
    <TextInputWrapper disabled={disabled} pointerEvents={disabled ? 'none' : undefined}>
      <TextInputLabel aria-label={label} nativeID={id} variant={variant}>
        {label}
      </TextInputLabel>
      <TextInputBox
        variant={variant}
        aria-label="input"
        aria-labelledby={id}
        background={background}
        placeholderTextColor={theme.division}
        editable={!disabled}
        selectTextOnFocus={!disabled}
        {...rest}
      />
      <TextInputMessage variant={variant}>{message}</TextInputMessage>
    </TextInputWrapper>
  )
}
