import {RouteSignedInParams} from '@ltb/libs/navigation/signed-in-navigator'
import {NativeStackScreenProps} from '@react-navigation/native-stack'
import styled from 'styled-components/native'
import {NavigationProp, useNavigation} from '@react-navigation/native'
import {Button} from '@ltb/libs/components/button'
import {fontS} from '@ltb/libs/design-system'
import {Header} from '@ltb/libs/components/header'
import {ROUTE_GENERIC_QUESTION, ROUTE_PLAN_HOME} from '@ltb/libs/navigation/constants'
import {useCMS} from '@ltb/libs/contexts/cms-context'
import {ScreenFragment} from '@ltb/libs/graphql/cms'
import {getNextScreen} from './utilities/get-next-generic-screen'
import {EdgeInsets, useSafeAreaInsets} from 'react-native-safe-area-context'
import {ProgressPips} from '@ltb/libs/components/progress-pips'

const BasicInfoWrapper = styled.View`
  display: flex;
  flex-grow: 1;
  background-color: ${({theme}) => theme.background};
  align-items: center;
  justify-content: center;
`

const InternalWrapper = styled.View`
  max-width: 500px;
  padding-left: 16px;
  padding-right: 16px;
  flex-grow: 1;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  gap: 10px;
`

const BottomWrapper = styled.View<{insets: EdgeInsets}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  width: 100%;
  padding-top: 16px;
  padding-bottom: ${({insets}) => insets.bottom || 16}px;
`

const BlurbText = styled.Text`
  ${fontS}
  color: ${({theme}) => theme.secondary};
`

type GenericQuestionProps = NativeStackScreenProps<RouteSignedInParams, typeof ROUTE_GENERIC_QUESTION> & {
  storybookComplete?: boolean
  storybookError?: boolean
  storybookLoading?: boolean
}

export const GenericQuestion = ({route}: GenericQuestionProps) => {
  const categoryId = route.params.categoryId
  const screenId = route.params.screenId
  const insets = useSafeAreaInsets()
  const navigation = useNavigation<NavigationProp<RouteSignedInParams>>()
  const {categories} = useCMS()

  const category = categories.find(cat => cat.id === categoryId)
  const numberOfScreens = category?.screen.length

  if (!category || !category.enabled || !category.visible) {
    navigation.navigate(ROUTE_PLAN_HOME)
    return null
  }

  const foundScreenIndex = category.screen.findIndex(scr => {
    return scr.id === screenId
  })
  const screen: ScreenFragment = screenId && foundScreenIndex ? category.screen[foundScreenIndex] : category.screen[0]
  if (!screen || !screen.enabled || !screen.visible || !screen.question || screen.question.length === 0) {
    navigation.navigate(ROUTE_PLAN_HOME)
    return null
  }
  const screenIndex = foundScreenIndex === -1 ? 0 : foundScreenIndex

  const questions = screen.question.filter(q => q.enabled && q.visible)
  if (!questions || questions.length === 0) {
    navigation.navigate(ROUTE_PLAN_HOME)
    return null
  }

  const nextScreen = getNextScreen(category.screen, screen)

  return (
    <BasicInfoWrapper>
      <Header leftIcon="ChevronLeft" leftOnPress={() => navigation.goBack()} title={category.title?.key} />
      <InternalWrapper>
        {screen?.question?.map(question => (
          <BlurbText key={question.id}>
            {question.title?.key} {numberOfScreens}
          </BlurbText>
        ))}

        <BottomWrapper insets={insets}>
          <ProgressPips total={numberOfScreens || 0} currentStep={screenIndex} size="medium" />
          <Button
            layout="responsive"
            type={nextScreen ? 'info' : 'success'}
            size="medium"
            onPress={() => {
              nextScreen
                ? navigation.navigate(ROUTE_GENERIC_QUESTION, {categoryId, screenId: nextScreen.id})
                : navigation.navigate(ROUTE_PLAN_HOME)
            }}>
            {nextScreen ? 'Next' : 'Finish'}
          </Button>
        </BottomWrapper>
      </InternalWrapper>
    </BasicInfoWrapper>
  )
}
