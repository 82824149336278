import React, {PropsWithChildren} from 'react'
import {StatusBar, useColorScheme} from 'react-native'
import {ThemeProvider} from 'styled-components/native'
import {darkTheme, lightTheme} from '@ltb/libs/design-system'
import {GestureHandlerRootView} from 'react-native-gesture-handler'
import {RootNavigator} from '../navigation/root-navigator'
import {ApolloProvider} from '@apollo/client'
import {apolloClient} from '../apollo'
import styled from 'styled-components/native'
import {SafeAreaProvider} from 'react-native-safe-area-context'
import Config from 'react-native-config'
import {AnalyticsProvider} from '../contexts/analytics-context'

const StyledView = styled.View`
  flex-grow: 1;
  background-color: ${({theme}) => theme.background};
`

/* istanbul ignore next */
const GQLProvider =
  process.env.NODE_ENV !== 'test' ? ApolloProvider : ({children}: PropsWithChildren) => <>{children}</>

const App = () => {
  const isDarkMode = useColorScheme() === 'dark'

  return (
    <GestureHandlerRootView style={{flex: 1}}>
      <AnalyticsProvider apiKey={Config.ANALYTICS_API_KEY || ''} trackingId={Config.ANALYTICS_ID || ''}>
        <GQLProvider client={apolloClient}>
          <ThemeProvider theme={isDarkMode ? darkTheme : lightTheme}>
            <SafeAreaProvider>
              <StyledView testID={isDarkMode ? 'dark-mode' : 'light-mode'}>
                <StatusBar barStyle={isDarkMode ? 'light-content' : 'dark-content'} />
                <RootNavigator />
              </StyledView>
            </SafeAreaProvider>
          </ThemeProvider>
        </GQLProvider>
      </AnalyticsProvider>
    </GestureHandlerRootView>
  )
}

export {App}
