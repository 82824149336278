import React from 'react'
import styled from 'styled-components/native'
import {NavigationProp, useNavigation} from '@react-navigation/native'
import {fontM, fontMContrast} from '@ltb/libs/design-system'
import {Header} from '@ltb/libs/components/header'
import {RouteSignedInParams} from '@ltb/libs/navigation/signed-in-navigator'
import {Card} from '@ltb/libs/components/card'
import {Pressable} from '@ltb/libs/components/pressable'
import {ROUTE_BASIC_INFO} from '@ltb/libs/navigation/constants'
import {useCreatePlanMutation} from '@ltb/libs/graphql/core'
import {useUser} from '@ltb/libs/contexts/user-context'

const BasicInfoWrapper = styled.View`
  display: flex;
  flex-grow: 1;
  background-color: ${({theme}) => theme.background};
  align-items: center;
  justify-content: center;
`

const InternalWrapper = styled.View`
  max-width: 800px;
  padding-top: 16px;
  padding-left: 16px;
  padding-right: 16px;
  flex-grow: 1;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  gap: 10px;
`

const SectionsWrapper = styled.View`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 10px;
`

const SectionTitle = styled.Text`
  color: ${({theme}) => theme.secondary};
  text-align: center;
  ${fontMContrast}
`
const SectionSubtext = styled.Text`
  color: ${({theme}) => theme.primary};
  text-align: center;
  ${fontM}
`
const StyledCard = styled(Card)`
  max-width: 150px;
`

type CreatePlanProps = {
  storybookLoading?: boolean
  storybookError?: string
  storybookComplete?: boolean
}

export const CreatePlan = ({}: CreatePlanProps) => {
  const navigation = useNavigation<NavigationProp<RouteSignedInParams>>()
  const {refetchUser} = useUser()

  const [createPlan] = useCreatePlanMutation({
    onCompleted: () => {
      refetchUser()
      navigation.navigate(ROUTE_BASIC_INFO)
    },
  })

  return (
    <BasicInfoWrapper>
      <Header leftIcon="ChevronLeft" leftOnPress={() => navigation.goBack()} title="Plan" />
      <InternalWrapper>
        <SectionsWrapper>
          <Pressable
            onPress={async () => {
              await createPlan()
            }}>
            <StyledCard type="midground" includePadding>
              <SectionTitle>For you</SectionTitle>
              <SectionSubtext>Create a plan for yourself</SectionSubtext>
            </StyledCard>
          </Pressable>
          <Pressable
            onPress={async () => {
              await createPlan()
            }}>
            <StyledCard type="midground" includePadding>
              <SectionTitle>For an other</SectionTitle>
              <SectionSubtext>Create a plan for an other</SectionSubtext>
            </StyledCard>
          </Pressable>
        </SectionsWrapper>
      </InternalWrapper>
    </BasicInfoWrapper>
  )
}
