import React, {PropsWithChildren} from 'react'
import {ButtonBody, ButtonLayout, ButtonSize, ButtonText, ButtonTextWrapper, ButtonVariant} from './button.style'
import {Iconography, Icons} from '@ltb/libs/design-system/iconography/iconography'
import {Pressable} from '../pressable'
import {useTheme} from 'styled-components/native'
import {palette, GlobalThemeType} from '@ltb/libs/design-system'
import {AnalyticClickParams} from '@ltb/libs/contexts/analytics-context/@types'
import {TouchableButtonProps} from '../pressable/pressable'

type ButtonProps = TouchableButtonProps & {
  layout: ButtonLayout
  variant?: ButtonVariant
  size: ButtonSize
  type: keyof typeof GlobalThemeType
  startIcon?: Icons
  endIcon?: Icons
  onPress: () => void
  disabled?: boolean
  onPressAnalytics?: AnalyticClickParams
}

export const Button = ({
  layout,
  size,
  type,
  variant = 'default',
  startIcon,
  endIcon,
  disabled = false,
  children,
  ...rest
}: PropsWithChildren<ButtonProps>) => {
  const theme = useTheme()

  const iconColorMap: Record<ButtonVariant, palette> = {
    default: theme[type].color,
    outline: theme[type].background,
    muted: theme[type].background,
    linked: theme[type].background,
  }

  const iconSizeMap: Record<ButtonSize, number> = {
    small: 12,
    medium: 14,
    large: 16,
  }

  return (
    <Pressable role="button" disabled={disabled} style={layout === 'responsive' && {width: '100%'}} {...rest}>
      <ButtonBody
        layout={layout}
        size={size}
        type={type}
        disabled={disabled}
        startIcon={Boolean(startIcon)}
        endIcon={Boolean(endIcon)}
        variant={variant}>
        <ButtonTextWrapper>
          {startIcon && <Iconography icon={startIcon} color={iconColorMap[variant]} size={iconSizeMap[size]} />}
          <ButtonText
            size={size}
            type={type}
            startIcon={Boolean(startIcon)}
            endIcon={Boolean(endIcon)}
            variant={variant}>
            {children}
          </ButtonText>
          {endIcon && <Iconography icon={endIcon} color={iconColorMap[variant]} size={iconSizeMap[size]} />}
        </ButtonTextWrapper>
      </ButtonBody>
    </Pressable>
  )
}
