import {fontLContrast} from '@ltb/libs/design-system'
import {EdgeInsets} from 'react-native-safe-area-context'
import styled from 'styled-components/native'

export const HeaderWrapper = styled.View<{insets: EdgeInsets}>`
  box-sizing: border-box;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  padding-top: ${({insets}) => insets.top}px;
  padding-left: ${({insets}) => insets.left}px;
  padding-right: ${({insets}) => insets.right}px;

  background-color: ${({theme}) => theme.midground};

  border-bottom-width: 1px;
  border-color: ${({theme}) => theme.division};
  border-style: solid;
`

export const HeadingText = styled.Text`
  ${fontLContrast}
  color: ${({theme}) => theme.primary};
`

export const IconWrapper = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 20px;
  height: 64px;
`
