import styled from 'styled-components/native'
import {NavigationProp, useNavigation} from '@react-navigation/native'
import {Button} from '@ltb/libs/components/button'
import {fontS} from '@ltb/libs/design-system'
import {Header} from '@ltb/libs/components/header'
import {RouteSignedInParams} from '@ltb/libs/navigation/signed-in-navigator'
import {ROUTE_PREPAREDNESS_QUESTION} from '@ltb/libs/navigation/constants'

const BasicInfoWrapper = styled.View`
  display: flex;
  flex-grow: 1;
  background-color: ${({theme}) => theme.background};
  align-items: center;
  justify-content: center;
`

const InternalWrapper = styled.View`
  max-width: 500px;
  padding-left: 16px;
  padding-right: 16px;
  flex-grow: 1;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  gap: 10px;
`

const BlurbText = styled.Text`
  ${fontS}
  color: ${({theme}) => theme.secondary};
`

type BasicInfoProps = {
  storybookLoading?: boolean
  storybookError?: string
  storybookComplete?: boolean
}

export const BasicInfoTwo = ({storybookLoading, storybookComplete}: BasicInfoProps) => {
  const navigation = useNavigation<NavigationProp<RouteSignedInParams>>()

  const data = false
  const loading = false

  const isComplete = storybookComplete || Boolean(data)
  const disabled = storybookLoading || loading || isComplete

  const isOwner = false
  return (
    <BasicInfoWrapper>
      <Header leftIcon="ChevronLeft" leftOnPress={() => navigation.goBack()} title="Basic Plan Info" />
      <InternalWrapper>
        <BlurbText>Do {isOwner ? 'you' : 'they'} already have a will?</BlurbText>
        <Button
          layout="responsive"
          type="success"
          size="medium"
          onPress={() => {
            navigation.navigate(ROUTE_PREPAREDNESS_QUESTION)
          }}
          disabled={disabled}>
          Yes
        </Button>
        <Button
          layout="responsive"
          type="error"
          variant="outline"
          size="medium"
          onPress={() => {
            navigation.navigate(ROUTE_PREPAREDNESS_QUESTION)
          }}
          disabled={disabled}>
          No
        </Button>
      </InternalWrapper>
    </BasicInfoWrapper>
  )
}
