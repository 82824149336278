import {PropsWithChildren, createContext, useContext, useEffect, useState} from 'react'
// import type { PageViewFn } from "./events.types";
import {getUniqueId} from 'react-native-device-info'
import type {ClickFn, PageViewFn} from './@types'

type AnalyticsContextType = {
  pageView: PageViewFn
  click: ClickFn
}

type AnalyticsProps = {
  apiKey: string
  trackingId: string
}

const GOOGLE_TRACKING_URL = 'https://www.google-analytics.com/mp/collect?'

/* istanbul ignore next */
const AnalyticsContext = createContext<AnalyticsContextType>({
  pageView: () => {},
  click: () => {},
})

export const AnalyticsProvider = ({apiKey, trackingId, children}: PropsWithChildren<AnalyticsProps>) => {
  const [clientId, setClientId] = useState<string | undefined>()

  const analyticsUrl =
    GOOGLE_TRACKING_URL +
    new URLSearchParams({
      api_secret: apiKey,
      measurement_id: trackingId,
    }).toString()

  useEffect(() => {
    const setId = async () => {
      const id = await getUniqueId()
      setClientId(prev => {
        if (prev === id) return prev
        return id
      })
    }

    void setId()
  }, [])

  const pageView: PageViewFn = params => {
    const eventBody = {
      client_id: clientId || '',
      events: [
        {
          name: 'page_view',
          params,
        },
      ],
    }

    fetch(analyticsUrl, {
      method: 'POST',
      body: JSON.stringify(eventBody),
    })
  }

  const click: ClickFn = params => {
    const eventBody = {
      client_id: clientId || '',
      events: [
        {
          name: 'click',
          params,
        },
      ],
    }

    fetch(analyticsUrl, {
      method: 'POST',
      body: JSON.stringify(eventBody),
    })
  }

  return (
    <AnalyticsContext.Provider
      value={{
        pageView,
        click,
      }}>
      {children}
    </AnalyticsContext.Provider>
  )
}

export const useAnalytics = () => useContext(AnalyticsContext)
