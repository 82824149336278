import {fontLContrast} from '@ltb/libs/design-system/'
import styled from 'styled-components/native'
import {ScrollView} from 'react-native'

const StyledText = styled.Text`
  color: ${({theme}) => theme.primary};
  ${fontLContrast}
`

export const Splash = () => {
  return (
    <ScrollView contentInsetAdjustmentBehavior="automatic">
      <StyledText>Little Tin Box - Splash</StyledText>
    </ScrollView>
  )
}
