import {useState} from 'react'
import styled from 'styled-components/native'
import {NavigationProp, useNavigation} from '@react-navigation/native'
import {Button} from '@ltb/libs/components/button'
import {TextInput} from '@ltb/libs/components/text-input'
import {fontS} from '@ltb/libs/design-system'
import {Header} from '@ltb/libs/components/header'
import {RouteSignedInParams} from '@ltb/libs/navigation/signed-in-navigator'
import {ROUTE_WILL_CONFIRMATION} from '@ltb/libs/navigation/constants'

const BasicInfoWrapper = styled.View`
  display: flex;
  flex-grow: 1;
  background-color: ${({theme}) => theme.background};
  align-items: center;
  justify-content: center;
`

const InternalWrapper = styled.View`
  max-width: 500px;
  padding-left: 16px;
  padding-right: 16px;
  flex-grow: 1;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  gap: 10px;
`

const BlurbText = styled.Text`
  ${fontS}
  color: ${({theme}) => theme.secondary};
`

const ErrorText = styled.Text`
  color: ${({theme}) => theme.error.background};
  ${fontS}
`

type BasicInfoProps = {
  storybookLoading?: boolean
  storybookError?: string
  storybookComplete?: boolean
}

export const BasicInfoOne = ({storybookLoading, storybookError, storybookComplete}: BasicInfoProps) => {
  const navigation = useNavigation<NavigationProp<RouteSignedInParams>>()

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')

  const handleSignUp = async () => {
    navigation.navigate(ROUTE_WILL_CONFIRMATION)
    console.log(firstName, lastName)
  }

  const isOwner = false

  const data = false
  const error = undefined
  const loading = false

  const isComplete = storybookComplete || Boolean(data)
  const isErrored = storybookError || (error !== undefined && !data)
  const disabled = storybookLoading || loading || isComplete

  return (
    <BasicInfoWrapper>
      <Header leftIcon="ChevronLeft" leftOnPress={() => navigation.goBack()} title="Basic Plan Info" />
      <InternalWrapper>
        <BlurbText>
          We need some basic information to get started. This information is specifically for the person whos plan your
          are creating.
        </BlurbText>
        <TextInput
          label={isOwner ? 'Your first name' : 'Their first name'}
          id="given-name"
          textContentType="givenName"
          autoCapitalize="none"
          autoComplete="given-name"
          onChangeText={setFirstName}
          disabled={disabled}
          variant={isErrored ? 'error' : undefined}
          testID="given-name-input"
        />
        <TextInput
          label={isOwner ? 'Your family name' : 'Their family name'}
          id="family-name"
          textContentType="familyName"
          autoComplete="family-name"
          onChangeText={setLastName}
          disabled={disabled}
          variant={isErrored ? 'error' : undefined}
          testID="family-name-input"
        />
        <TextInput
          label={isOwner ? 'Your date of birth' : 'Their date of birth'}
          id="date-of-birth"
          textContentType="birthdate"
          autoComplete="birthdate-full"
          onChangeText={() => {}}
          disabled={disabled}
          variant={isErrored ? 'error' : undefined}
          testID="dob-input"
        />
        <ErrorText>{isErrored ? 'Saving information failed' : ''}</ErrorText>
        <Button layout="responsive" type="info" size="medium" onPress={handleSignUp} disabled={disabled}>
          {isComplete ? 'Continuing' : disabled ? 'Saving info...' : 'Continue'}
        </Button>
      </InternalWrapper>
    </BasicInfoWrapper>
  )
}
