export enum primary {
  depth = '#000D3A',
  shallow = '#001C83',
  ocean = '#0027B8',
  deepBlue = '#001B7C',
  trench = '#000826',
}

export enum elaborate {
  ultramarine = '#0437F2',
  white = '#FFFFFF',
  evil = '#F2042F',
  mint = '#00BC8F',
  mustard = '#FF8A00',
}

export enum pastel {
  emptyMustard = '#FFE3C2',
  boiledMint = '#ECF3F2',
  marine = '#EAEEFF',
  lesserEvil = '#FFEAEA',
  rainCloud = '#E1E1E1',
  cloud = '#F5F6FF',
}

export const palette = {...primary, ...elaborate, ...pastel}
export type palette = primary | elaborate | pastel

export type ValidColor = palette | 'transparent'
