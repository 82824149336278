import React, {PropsWithChildren} from 'react'
import Animated, {SlideInLeft, SlideOutLeft, SlideInRight, SlideOutRight} from 'react-native-reanimated'

type SlideIn = {
  originDirection: 'left' | 'right'
}

export const SlideIn = ({originDirection, children}: PropsWithChildren<SlideIn>) => {
  const entering = originDirection === 'left' ? SlideInLeft : SlideInRight
  const exiting = originDirection === 'left' ? SlideOutLeft : SlideOutRight
  return (
    <Animated.View entering={entering} exiting={exiting} style={{flexGrow: 1, backgroundColor: 'transparent'}}>
      {children}
    </Animated.View>
  )
}
