import {palette} from '@ltb/libs/design-system'
import styled from 'styled-components/native'

export const SideModalWrapper = styled.View`
  box-sizing: border-box;
  display: flex;
  flex: 1;
  background-color: ${palette.trench}CC;
`

export const BackgroundPressable = styled.Pressable<{originDirection: 'left' | 'right'}>`
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: ${({originDirection}) => (originDirection === 'left' ? 'flex-start' : 'flex-end')};
`

export const ModalContainer = styled.View<{width: number; originDirection: 'left' | 'right'}>`
  box-sizing: border-box;
  background-color: ${({theme}) => theme.midground};
  ${({originDirection, theme}) => {
    const direction = originDirection === 'left' ? 'right' : 'left'
    return `
      border-${direction}-width: 1px;
      border-${direction}-style: solid;
      border-${direction}-color: ${theme.division};
    `
  }};
  height: 100%;
  width: ${({width}) => `${width}px`};
  max-width: 400px;
`
