import '@ltb/libs/design-system/global-reset.css'
import '@ltb/libs/design-system/typography.css'
import 'react-native-gesture-handler'

import {AppRegistry} from 'react-native'
import {createRoot} from 'react-dom/client'
import {enableExperimentalWebImplementation} from 'react-native-gesture-handler'
import {App} from '@ltb/libs/client/app'
import {name as appName} from '@ltb/libs/client/app.config.json'

enableExperimentalWebImplementation(true)

AppRegistry.registerComponent(appName, () => App)

const root = createRoot(document.getElementById('root') as Element)
root.render(<App />)
