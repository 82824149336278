import React, {useState} from 'react'
import {createNativeStackNavigator} from '@react-navigation/native-stack'
import {DocumentTitleOptions, LinkingOptions, NavigationContainer} from '@react-navigation/native'
import {
  ROUTE_SPLASH,
  ROUTE_SIGNED_IN_STACK,
  ROUTE_SIGNED_OUT_STACK,
  ROUTE_NOT_FOUND,
  ROUTE_SIGNED_IN_LANDING,
  ROUTE_LOGIN,
  ROUTE_SIGNED_OUT_LANDING,
  ROUTE_SIGN_UP,
  ROUTE_BASIC_INFO,
  ROUTE_PLAN_HOME,
  ROUTE_GENERIC_QUESTION,
} from './constants'
import {UserFragment, useCurrentUserQuery} from '../graphql/core'
import {SignedInNavigator} from './signed-in-navigator'
import {SignedOutNavigator} from './signed-out-navigator'
import {Splash} from '../screens/splash'
import {useTheme} from 'styled-components/native'
import {AppWrapperProvider} from '../contexts/app-wrapper-context'
import {FourZeroFour} from '../screens/404'
import Config from 'react-native-config'

export type RouteRootParams = {
  [ROUTE_SIGNED_IN_STACK]: {user: UserFragment}
  [ROUTE_SIGNED_OUT_STACK]: undefined
  [ROUTE_SPLASH]: undefined
  [ROUTE_NOT_FOUND]: undefined
}

const linking: LinkingOptions<RouteRootParams> = {
  prefixes: [Config.WEB_URL || '', Config.DEEP_LINK_URL || ''],
  config: {
    screens: {
      [ROUTE_SPLASH]: '',
      [ROUTE_NOT_FOUND]: '404',
      [ROUTE_SIGNED_IN_STACK]: {
        // @ts-ignore
        screens: {
          [ROUTE_SIGNED_IN_LANDING]: 'accounts',
          [ROUTE_BASIC_INFO]: 'basic-info',
          [ROUTE_PLAN_HOME]: 'plans',
          [ROUTE_GENERIC_QUESTION]: 'questions',
        },
      },
      [ROUTE_SIGNED_OUT_STACK]: {
        screens: {
          [ROUTE_SIGNED_OUT_LANDING]: 'home',
          [ROUTE_LOGIN]: 'login',
          [ROUTE_SIGN_UP]: 'signup',
        },
      },
    },
  },
}

const Stack = createNativeStackNavigator<RouteRootParams>()

export const documentTitle: DocumentTitleOptions = {
  formatter: (_, route) => {
    return `${route?.name} - Little Tin Box`
  },
}

export const RootNavigator = () => {
  const [localUser, setLocalUser] = useState<UserFragment | undefined>(undefined)
  const theme = useTheme()
  const background = {backgroundColor: theme.background}

  const {data, loading} = useCurrentUserQuery({
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
  })

  const user = data?.currentUser || localUser
  const isLoggedIn = localUser || Boolean(user)

  return (
    <NavigationContainer linking={linking} fallback={<Splash />} documentTitle={documentTitle}>
      <AppWrapperProvider setLocalUser={setLocalUser}>
        <Stack.Navigator
          screenOptions={{
            headerShown: false,
            gestureEnabled: false,
            contentStyle: background,
          }}>
          {loading && <Stack.Screen name={ROUTE_SPLASH} component={Splash} />}
          {!loading && isLoggedIn ? (
            <Stack.Screen name={ROUTE_SIGNED_IN_STACK} component={SignedInNavigator} initialParams={{user}} />
          ) : (
            <Stack.Screen name={ROUTE_SIGNED_OUT_STACK} component={SignedOutNavigator} />
          )}
          <Stack.Screen name={ROUTE_NOT_FOUND} component={FourZeroFour} />
        </Stack.Navigator>
      </AppWrapperProvider>
    </NavigationContainer>
  )
}
