import {palette} from './index'

export enum GlobalThemeType {
  info = 'info',
  warn = 'warn',
  success = 'success',
  error = 'error',
}

type GlobalTheme = {
  background: palette
  color: palette
}

const global: Record<GlobalThemeType, GlobalTheme> = {
  info: {
    background: palette.ultramarine,
    color: palette.marine,
  },
  warn: {
    background: palette.mustard,
    color: palette.emptyMustard,
  },
  success: {
    background: palette.mint,
    color: palette.boiledMint,
  },
  error: {
    background: palette.evil,
    color: palette.lesserEvil,
  },
}

export type ThemeBase = typeof global & {
  theme: 'light' | 'dark'
  background: palette
  midground: palette
  primary: palette
  secondary: palette
  division: palette
}

export const lightTheme: ThemeBase = {
  theme: 'light',
  background: palette.cloud,
  midground: palette.white,
  primary: palette.deepBlue,
  secondary: palette.ocean,
  division: palette.rainCloud,
  ...global,
}

export const darkTheme: ThemeBase = {
  theme: 'dark',
  background: palette.trench,
  midground: palette.depth,
  primary: palette.white,
  secondary: palette.cloud,
  division: palette.shallow,
  ...global,
}
