import React, {PropsWithChildren} from 'react'
import styled from 'styled-components/native'
import type {palette} from '@ltb/libs/design-system'
import {useTheme} from 'styled-components/native'
import {DefaultTheme} from 'styled-components/native'
import {StyleProp, ViewStyle} from 'react-native'

type CardTypes = 'background' | 'midground'

const cardBackgrounds: Record<CardTypes, (theme: DefaultTheme) => palette> = {
  background: theme => theme.background,
  midground: theme => theme.midground,
}

const CardWrapper = styled.View<{bgColour: palette; includePadding: boolean}>`
  background-color: ${({bgColour}) => bgColour};
  border: 1px solid ${({theme}) => theme.division};
  border-radius: 5px;
  padding: ${({includePadding}) => (includePadding ? '16px' : '0')};
`

type CardProps = {
  type: CardTypes
  includePadding?: boolean
  style?: StyleProp<ViewStyle>
}

export const Card = ({type, style, children, includePadding = false}: PropsWithChildren<CardProps>) => {
  const theme = useTheme()
  const bgColour = cardBackgrounds[type](theme)
  return (
    <CardWrapper bgColour={bgColour} includePadding={includePadding} style={style}>
      {children}
    </CardWrapper>
  )
}
