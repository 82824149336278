import {createNativeStackNavigator} from '@react-navigation/native-stack'
import {ROUTE_SIGNED_OUT_LANDING, ROUTE_SIGN_UP, ROUTE_LOGIN} from './constants'
import {SignedOutLanding} from '../screens/signed-out-landing'
import {useTheme} from 'styled-components/native'
import {Login} from '../screens/login'
import {SignUp} from '../screens/sign-up'

export type RouteSignedOutParams = {
  [ROUTE_SIGNED_OUT_LANDING]: undefined
  [ROUTE_LOGIN]: undefined
  [ROUTE_SIGN_UP]: undefined
}

const Stack = createNativeStackNavigator<RouteSignedOutParams>()

export const SignedOutNavigator = () => {
  const theme = useTheme()
  const background = {backgroundColor: theme.background}
  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: false,
        gestureEnabled: false,
        contentStyle: background,
      }}>
      <Stack.Screen name={ROUTE_SIGNED_OUT_LANDING} component={SignedOutLanding} />
      <Stack.Screen name={ROUTE_LOGIN} component={Login} />
      <Stack.Screen name={ROUTE_SIGN_UP} component={SignUp} />
    </Stack.Navigator>
  )
}
